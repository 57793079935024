import './Title.css';
import 'firebase/firestore';
import { useFirestoreDoc, useFirestore } from 'reactfire';

export default function Title() {
  const homeDocRef = useFirestore().collection("example").doc("home");
  const homeDoc = useFirestoreDoc(homeDocRef);
  const data = homeDoc && homeDoc.data && typeof homeDoc.data.data === "function" ? homeDoc.data.data() : {};

  return (
    <div className="Title">
      <p className="title">{data?.title || "?"}</p>
      <p className="subtitle">{data?.subtitle? data.subtitle : ""}</p>
    </div>
  );
}
